<template>
  <div class="content">
    <h2>Termos & Condições de Uso</h2>

    <p>
      Estes Termos &amp; Condições de Uso definem o relacionamento entre a Otto
      Finanças ("nós") e o visitante, o usuário, ou outras pessoas ("usuário"
      e/ou "você") que queiram acessar ou usar o site
      <router-link to="/" target="_blank">www.ottofinancas.com.br</router-link>
      ou o aplicativo e plataforma Web Otto Finanças ("App"), juntos, chamados
      de "Serviço" operados pela Otto Finanças.
    </p>

    <p>
      O seu acesso ao e uso do Serviço está condicionado à aceitação e
      cumprimento destes Termos & Condições de Uso e os Termos de Serviço do
      aplicativo Otto Finanças. Ao acessar ou usar o Serviço, você concorda em
      estar sujeito a estes Termos. Caso você não concorde com qualquer parte
      dos Termos, não terá permissão para acessar o Serviço.
    </p>

    <p>
      Estes Termos & Condições de Uso se aplicam ao grupo Otto Sistemas Ltda.,
      uma empresa registrada no Brasil, cujo endereço é Rua Domingos Barbosa de
      Araújo, Nº 519, Sl 202, Kalilândia, Feira de Santana/BA, 44001-280;
    </p>

    <ol>
      <li>
        Direito ao Uso do App e plataforma Web
        <p>
          Se você não concorda com os Termos, favor não fazer o download do
          nosso App e nem se cadastre na plataforma Web. Se já iniciou o
          download, por favor clique em "Cancelar", o que encerrará o processo
          de download.
        </p>

        <p>
          Caso você tenha feito o download do nosso App em um telefone celular
          ou dispositivo móvel que não seja de sua propriedade, nós assumiremos
          que você teve permissão dos proprietários do dispositivo para baixar
          uma cópia do nosso App nesse mesmo dispositivo, isentando a Otto de
          qualquer responsabilidade, caso a permissão não tenha sido obtida.
          Você pode ser cobrado por seus fornecedores de serviços pelo acesso à
          Internet no seu dispositivo, custos esses que correrão por conta do
          Usuário. Ao fazer o download do nosso App, você aceita integralmente a
          responsabilidade, e concorda com estes Termos de uso e licenciamento
          do nosso App e/ou qualquer serviço nosso em qualquer dispositivo usado
          por você, independentemente dele pertencer ou não a você.
        </p>

        <p>
          Damos a você a licença para o uso do nosso App sujeita ao aceite
          destes Termos, e o submetemos às regras e políticas aplicadas por
          qualquer fornecedor ou operador de loja de aplicativos de onde você
          fez o download do nosso App. Nós não vendemos nosso App a você ou a
          qualquer usuário, de modo que continuamos sendo os proprietários do
          App o tempo todo.
        </p>

        <p>
          Nosso aplicativo está disponível para dispositivos móveis com sistemas
          operacionais Apple iOS e Android OS.
        </p>

        <p>
          Nós e nossos subcontratados não aceitamos qualquer responsabilidade
          pela indisponibilidade do nosso App, ou qualquer dificuldade ou
          incapacidade de baixar ou acessar o conteúdo ou qualquer outra falha
          no sistema de comunicação que possa resultar na indisponibilidade do
          nosso App ou Serviço. Não seremos responsáveis por qualquer suporte ou
          manutenção ao nosso App.
        </p>

        <p>
          Para usar nosso App, você precisa de um telefone celular ou
          dispositivo móvel compatível, acesso à Internet e às especificações
          mínimas necessárias. A versão do software do nosso App pode ser
          atualizada de tempos em tempos para suportar novas funções e serviços.
        </p>

        <p>
          De tempos em tempos, as atualizações do nosso App podem ser publicadas
          pela loja de aplicativos correspondente. Dependendo da atualização, é
          possível que você não consiga usar nosso App até fazer o download da
          versão mais recente do App e aceitar novos termos.
        </p>
      </li>

      <li>
        Contas
        <p>
          Ao criar uma conta conosco, você garante que tem mais de 18 anos e que
          as informações que você nos fornece são precisas, completas e
          atualizadas a todo momento. Informações imprecisas, incompletas ou
          obsoletas podem resultar no cancelamento imediato da sua conta no
          Serviço e descontinuidade do licenciamento do App.
        </p>

        <p>
          Você é totalmente responsável por manter a confidencialidade de sua
          conta e senha, incluindo, mas não limitado, a restrição de acesso ao
          seu computador, dispositivo e/ou conta. Você concorda em aceitar a
          responsabilidade por toda e qualquer atividade ou ação que ocorra em
          sua conta e/ou senha, independentemente de sua senha estar em nosso
          Serviço ou em um serviço de terceiros. Você deve nos notificar
          imediatamente após tomar conhecimento de qualquer violação de
          segurança ou uso não autorizado da sua conta.
        </p>

        <p>
          Ao usar nosso Serviço, você deve cumprir as disposições das nossas
          <router-link :to="{ name: 'privacy-policy' }" target="_blank"
            >Diretrizes da Comunidade.</router-link
          >
          --> Você é responsável por tomar todas as providências necessárias
          para poder acessar o nosso App. Você também é responsável por garantir
          que todas as pessoas que acessam nosso App através do seu dispositivo
          estejam cientes desses Termos, e que ajam de acordo com eles.
        </p>

        <p>
          Você não pode usar como nome de usuário o nome de outra pessoa,
          entidade, um nome que não esteja legalmente disponível para uso, ou um
          nome ou marca comercial que esteja sujeita a quaisquer direitos de
          outra pessoa ou entidade que não seja você, sem a devida autorização.
          Você também não pode usar como nome de usuário qualquer nome que seja
          ofensivo, vulgar ou obsceno.
        </p>
      </li>

      <li>
        Versão Premium
        <p>
          A versão Premium é comercializada através de uma assinatura recorrente
          (com pagamento mensal ou anual). As assinaturas são renovadas
          automaticamente ao término de cada ciclo de pagamento, mensalmente ou
          anualmente. Após o término de um ciclo de pagamento, a não renovação
          da assinatura implicará na desabilitação das funcionalidades Premium.
        </p>

        <p>
          Os valores das assinaturas, podem variar de acordo com sazonalidade,
          promoções e inclusive diferir por plataforma. O valor será apresentado
          sempre, de forma clara, na interface que o usuário está utilizando
          para efetuar sua aquisição.
        </p>
      </li>

      <li>
        Coleta e Uso de Informações
        <p>
          Por meio dos nossos serviços, podemos usar meios automáticos
          (incluindo, por exemplo, cookies e web beacons) para coletar e
          realizar tratamento de informações sobre o seu uso dos nossos
          serviços. Você também pode ser solicitado a fornecer determinadas
          informações sobre si mesmo como condição para baixar, instalar ou usar
          nossos serviços, certos recursos ou funcionalidades. Todas as
          informações que coletamos por meio da ou durante a sua conexão com
          nossos serviços estão sujeitas à nossa
          <router-link :to="{ name: 'privacy-policy' }" target="_blank"
            >Política de Privacidade da Otto</router-link
          >
          ("Política de Privacidade"). Os dados coletados e compartilhados com a
          Otto terão sua utilização e tratamento melhor descritos na nossa
          Política de Privacidade.
        </p>
      </li>

      <li>
        Propriedade Intelectual
        <p>
          O Serviço e seu conteúdo, recursos e funcionalidades originais são e
          continuarão sendo propriedade exclusiva da Otto Finanças e de seus
          licenciadores. Esse Serviço está protegido por, entre outras coisas,
          direitos autorais, leis de marca comercial e outras leis no Brasil,
          Estados Unidos e outros países. Todos esses direitos são reservados.
          Nossas marcas comerciais e imagem comercial não podem ser usadas por
          ou associadas a qualquer outro produto ou serviço sem o consentimento
          prévio da Otto Finanças. Licenciamos o uso do Serviço com base nestes
          Termos.
        </p>
      </li>

      <li>
        Seu Conteúdo
        <p>
          O Serviço pode conter informações, textos, links, gráficos, fotos,
          imagens, vídeos ou outros materiais, publicados pelos próprios
          usuários do App ("Conteúdo"), incluindo Conteúdo criado com, ou
          enviado para os Serviços por você ou por meio de sua Conta ("Seu
          Conteúdo"). Não assumimos nenhuma responsabilidade e não endossamos
          expressa ou implicitamente o Seu Conteúdo. A Otto Finanças não
          divulgará nenhuma informação pessoal financeira fornecida.
        </p>

        <p>
          Ao enviar o Seu Conteúdo para o Serviço, você declara e garante que
          possui todos os direitos, poder e autoridade necessários para atender
          aos direitos do Seu Conteúdo contidos nestes Termos. Como você é o
          único responsável pelo Seu Conteúdo, você pode se expor a riscos caso
          publique ou compartilhe Conteúdo sem todos os direitos necessários.
        </p>

        <p>
          Você mantém todos os direitos de domínio sobre o Seu Conteúdo, mas
          concede à Otto Finanças a seguinte licença para usar esse Conteúdo:
        </p>

        <p>
          Quando o Seu Conteúdo é criado com, ou enviado aos Serviços, você nos
          concede uma licença mundial, gratuita, livre de royalties, perpétua,
          irrevogável, não exclusiva, transferível e sublicenciável para usar,
          copiar, modificar, adaptar, preparar trabalhos derivados, distribuir e
          exibir o Seu Conteúdo e qualquer nome, nome de usuário, voz ou imagem
          fornecidos relacionados ao Seu Conteúdo em todos os formatos e canais
          de mídia conhecidos hoje ou desenvolvidos no futuro. Essa licença
          inclui o direito de disponibilizarmos o Seu Conteúdo para sindicação,
          transmissão, distribuição ou publicação por outras empresas,
          organizações ou indivíduos que são parceiros da Otto Finanças. Você
          também concorda que podemos remover metadados associados ao Seu
          Conteúdo, e você renuncia irrevogavelmente a quaisquer reivindicações
          e afirmações de direitos morais ou concessões em relação ao Seu
          Conteúdo.
        </p>

        <p>
          Quaisquer ideias, sugestões e comentários sobre a Otto Finanças ou
          nosso Serviço que você nos fornecer são totalmente voluntários e você
          concorda que a Otto Finanças pode usar tais ideias, sugestões e
          comentários sem compensação ou obrigação para com você.
        </p>

        <p>
          Mesmo não sendo exigido de nós, podemos, exclusivamente a nosso
          critério, examinar, excluir ou remover Seu Conteúdo a qualquer momento
          e por qualquer motivo, inclusive por violação destes Termos, violação
          de nossas
          <router-link :to="{ name: 'privacy-policy' }" target="_blank">
            Diretrizes da Comunidade</router-link
          >, ou se de algum outro modo você gere algum risco para nós.
        </p>
      </li>

      <li>
        Integração de contas bancárias

        <p>
          Caso deseje melhorar sua experiência, o Usuário poderá informar ao
          OTTO seus dados bancários (por exemplo, banco, agência, conta
          corrente/poupança, número do cartão de crédito), juntamente com a
          senha de acesso ao internet banking, expressamente autorizando o OTTO
          a acessar seus dados financeiros disponibilizados no internet banking
          das respectivas instituições financeiras.
        </p>

        <p>
          Para acessar seus dados financeiros em bancos ou instituições, nós
          utilizamos nossa própria tecnologia, ou contratamos parceiros
          específicos (consulte nossa política de privacidade aqui ). Ao optar
          pela integração bancária no OTTO, você precisa concordar e consentir
          com as políticas de privacidade de nossos parceiros, disponibilizadas
          a você em nossa plataforma, no ato da adesão à integração bancária.
          Caso não deseje fornecer seu consentimento, a OTTO não compartilhará
          nenhum dado pessoal seu, nem realizar a integração bancária.
        </p>

        <p>
          Uma vez que a senha solicitada somente permite leitura, as únicas
          ações do OTTO relacionadas aos dados bancários fornecidos pelo Usuário
          são a integração dessas informações à sua plataforma, e o
          compartilhamento dos dados com parceiros que podem nos fornecer este
          serviço, de modo que o Usuário consiga usufruir os benefícios do OTTO
          de maneira mais fidedigna com a realidade. A OTTO não consegue
          movimentar valores, realizar operações financeiras e nem interferir,
          de modo algum, nos ativos e contas bancárias informadas pelo Usuário.
        </p>

        <p>
          O Usuário deve estar ciente de que a obtenção dos dados bancários pela
          OTTO depende de serviços prestados pelas instituições financeiras
          indicadas pelo Usuário. Por isso, não temos como garantir a
          pontualidade, precisão, entrega ou ausência de falha na obtenção dos
          Dados Financeiros por meio das plataformas dessas instituições, já que
          a importação desses dados poderá estar sujeita a problemas técnicos ou
          outras dificuldades de conexão que resultem em falha na sua obtenção.
        </p>

        <p>
          Além disso, o OTTO coleta as informações das plataformas das
          instituições financeiras informadas pelo Usuário, mas não revisa os
          dados obtidos. Por esse motivo, o Usuário deverá conferir as
          informações disponibilizadas pelo OTTO sobre os dados financeiros
          antes de tomar qualquer decisão baseada neles.
        </p>
      </li>

      <li>
        Conteúdo dos Outros
        <p>
          Uma parte significativa do conteúdo do nosso Serviço é produzida por
          outros usuários. Esse conteúdo é de responsabilidade exclusiva da
          pessoa ou organização que o publicou. Embora a Otto se reserva o
          direito de revisar ou remover todo ou qualquer conteúdo que apareça
          nos Serviços, nós não necessariamente analisamos todo o conteúdo.
          Portanto, não podemos e não assumimos responsabilidade por qualquer
          conteúdo fornecido por outras pessoas através dos Serviços.
        </p>

        <p>
          Não queremos que o Serviço seja usado de maneira indevida ou
          maliciosa. Porém, como não analisamos todo o conteúdo, não podemos
          garantir que o conteúdo do Serviço ou que o uso do nosso Serviço pelos
          usuários esteja sempre em conformidade com as diretrizes estabelecidas
          em nossos Termos &amp; Condições de Uso.
        </p>
      </li>

      <li>
        Respeitando o Direito dos Outros
        <p>
          Você não pode usar o Serviço ou permitir que outra pessoa o utilize,
          de uma maneira que:
        </p>
        <ul class="inner-list">
          <li>
            viole os direitos de publicidade, privacidade, direitos autorais,
            marca comercial ou outro direito de propriedade intelectual de
            outrem;
          </li>
          <li>importune, assedie ou intimide, difame e/ou</li>
          <li>
            gere spam ou faça solicitações indesejadas aos nossos usuários.
          </li>
        </ul>
        <p></p>
      </li>

      <li>
        Link para Outros Sites
        <p>
          Nosso Serviço pode conter links para sites ou serviços de terceiros
          que não pertencem ou não são controlados pela Otto Finanças.
        </p>

        <p>
          A Otto Finanças não tem controle e não assume nenhuma responsabilidade
          pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites
          ou serviços de terceiros. Não garantimos as ofertas, conteúdos,
          mensagens e informações transmitidas de nenhuma dessas
          entidades/indivíduos ou de seus sites.
        </p>

        <p>
          Você reconhece e concorda que a Otto não deverá ser responsável,
          direta ou indiretamente, por qualquer dano ou perda causada (ou
          pressupostamente causada) por causa do uso ou de ações tomadas
          baseadas nesses conteúdos, bens ou serviços disponíveis em qualquer
          sites ou serviços de terceiros.
        </p>

        <p>
          É altamente recomendável que você leia os Termos &amp; Condições e
          Políticas de Privacidade de quaisquer sites ou serviços de terceiros
          que você visitar.
        </p>
      </li>

      <li>
        Violação de Direitos Autorais
        <p>
          A Otto Finanças respeita as leis de direitos autorais. Portanto,
          tomamos medidas razoáveis para remover rapidamente de nossos Serviços
          qualquer material infrator de que tenhamos conhecimento. Se tomarmos
          conhecimento de que um de nossos usuários violou repetidamente os
          direitos autorais, tomaremos medidas cabíveis e ao nosso alcance para
          encerrar a conta do usuário.
        </p>

        <p>
          Fazemos com que seja fácil para você reportar suspeitas de violação de
          direitos autorais. Se você acredita que algo nos Serviços viola os
          direitos autorais que você tem ou controla, informe-nos enviando um
          e-mail para
          <a href="mailto:contato@ottofinancas.com.br" target="_blank"
            >contato@ottofinancas.com.br</a
          >
        </p>
      </li>

      <li>
        Suspensão de Contas
        <p>
          Podemos rescindir ou suspender sua conta e impedir seu acesso ao
          Serviço a qualquer momento, sem aviso prévio, se:
        </p>
        <ul class="inner-list" style="list-style-type: upper-latin">
          <li>
            você violar, ou a Otto Finanças tiver suspeitas razoáveis de que
            você violou, a Seção 5 (Propriedade Intelectual), Seção 6 (Seu
            Conteúdo), Seção 9 (Respeitando o Direito dos Outros) e/ou Seção 16
            (Elegibilidade) dos Termos;
          </li>
          <li>
            você violou ou a Otto Finanças tiver suspeitas razoáveis de que
            violou alguma de nossas políticas;
          </li>
          <li>
            sua conduta no Serviço for considerada pela Otto Finanças, ou por
            outro usuário, difamatória; ou
          </li>
          <li>sua conta precisar ser removida devido a conduta ilegal.</li>
        </ul>
        <p></p>

        <p>
          Podemos rescindir sua conta e estes Termos a qualquer momento,
          fornecendo a você aviso prévio por escrito com 30 dias de
          antecedência. Esse aviso será fornecido por meio de uma notificação em
          nosso App ou site, ou pelo endereço de e-mail mais recente que você
          nos forneceu.
        </p>

        <p>
          Se você deseja encerrar sua conta, pode fazê-lo através do App da Otto
          Finanças ou enviando um e-mail para
          <a href="mailto:contato@ottofinancas.com.br" target="_blank"
            >contato@ottofinancas.com.br</a
          >
        </p>

        <p>
          Todas as disposições dos Termos que, por sua natureza, devam
          prevalecer ao término, prevalecerão ao término, incluindo, sem
          limitação, a Seção 5 (Propriedade Intelectual), Seção 6 (Seu
          Conteúdo), Seção 12 (Suspensão da Conta), Seção 13 (Indenização),
          Seção 14 (Limitação de Responsabilidade), Seção 15 (Aviso Legal),
          Seção 17 (Exclusões) e Seção 18 (Lei Aplicável).
        </p>
      </li>

      <li>
        Indenização
        <p>
          Você concorda em defender, indenizar e isentar a Otto Finanças "como
          um todo" de e contra todas e quaisquer reclamações, danos, obrigações,
          perdas, responsabilidades, custos ou débitos e despesas (incluindo,
          mas não limitado a, honorários advocatícios), juros, julgamentos,
          acordos e penalidades incorridos pela Otto Finanças, relacionados a:
        </p>
        <ul class="inner-list" style="list-style-type: upper-latin">
          <li>
            seu uso e acesso ao Serviço, por você ou qualquer pessoa que use sua
            conta e senha; ou
          </li>
          <li>uma violação destes Termos.</li>
        </ul>
        <p></p>
      </li>

      <li>
        Limitação de Responsabilidade
        <p>
          O material e as informações exibidas em nosso Serviço são fornecidas
          sem garantias ou condições relacionadas à sua precisão.
        </p>

        <p>Nada nestes Termos exclui ou limita:</p>
        <ul class="inner-list" style="list-style-type: lower-roman">
          <li>
            a responsabilidade da Otto Finanças por morte ou danos pessoais
            causados por negligência da Otto Finanças ou por representação
            inapropriada; ou
          </li>
          <li>
            a responsabilidade de qualquer das partes por quaisquer
            responsabilidades que não possam ser legalmente excluídas ou
            limitadas.
          </li>
        </ul>
        <p></p>

        <p>
          Sujeito ao disposto acima, em nenhum caso a Otto Finanças, nem seus
          diretores, funcionários, parceiros, agentes, fornecedores ou
          afiliados, serão responsabilizados, seja por contrato, delito
          (incluindo negligência), quebra de dever estatutário, deturpação ou
          outra forma, independentemente de termos sido informados da
          possibilidade de tais danos, por uma das seguintes coisas relacionadas
          ao ou decorrentes do Serviço ou destes Termos:
        </p>
        <ul class="inner-list" style="list-style-type: upper-latin">
          <li>
            qualquer perda ou dano indireto, incidental, especial, consequencial
            ou punitivo, seja qual for o motivo; ou
          </li>
          <li>
            qualquer perda de lucros, receita, dados, uso, ágio, direto ou
            indireto.
          </li>
        </ul>
        <p></p>

        <p>
          Sujeito ao disposto acima, a responsabilidade total agregada da Otto
          Finanças em contrato, delito (incluindo negligência), quebra de dever
          estatutário, deturpação ou de outra forma relacionada ao Serviço ou a
          ele decorrente ou a estes Termos não deve exceder R$ 100,00.
        </p>
      </li>

      <li>
        Aviso Legal
        <p>
          Nós temos a intenção de atualizar nosso Serviço regularmente, e
          podemos alterar seu conteúdo a qualquer momento. Nós podemos, de
          tempos em tempos, sem incorrer em qualquer responsabilidade para você,
          suspender ou retirar temporariamente toda ou qualquer funcionalidade
          do Serviço pelos períodos que determinarmos, por motivos que incluem,
          mas não se limitam a, razões técnicas, de segurança, manutenção,
          administrativas ou outras (independentemente se estão sob nosso
          controle ou não).
        </p>

        <p>
          Qualquer conteúdo do nosso Serviço pode estar desatualizado a qualquer
          momento, e não temos obrigação de atualizar esse conteúdo.
        </p>

        <p>
          Sujeito à lei aplicável, o Serviço está disponível para todos os
          usuários da forma "como é apresentado", sem quaisquer garantias de
          qualquer tipo. Salvo conforme expressamente estabelecido nestes
          Termos, todas as condições, garantias ou outros termos, expressos ou
          implícitos (implícitos em estatuto, direito comum, curso de negociação
          ou outro), são expressamente excluídos na extensão máxima permitida
          por lei. Não damos garantias ou compromissos de que o Serviço, ou o
          servidor que o disponibiliza, estará livre de defeitos.
        </p>
      </li>

      <li>
        Elegibilidade
        <p>
          Os nossos serviços foram projetados para usuários que atendem aos
          seguintes critérios:
        </p>
        <ul class="inner-list" style="list-style-type: upper-latin">
          <li>São maiores de 18 anos;</li>
          <li>Vivem na República Federativa do Brasil;</li>
          <li>
            Pretendem usar os nossos serviços para fins pessoais e não
            comerciais.
          </li>
          <li>
            Cujo uso ou acesso ao nosso Serviço não viole nenhuma lei aplicável.
          </li>
        </ul>
        <p></p>
      </li>

      <li>
        Exclusões
        <p>
          Algumas jurisdições não permitem a exclusão de certas garantias ou a
          exclusão ou limitação de responsabilidade por danos consequentes ou
          incidentais; portanto, as limitações acima podem não se aplicar a
          você.
        </p>
      </li>

      <li>
        Lei Aplicável
        <p style="margin-bottom: 0">
          <strong
            >Se você é um usuário dos nossos serviços com sede no Brasil:<br
          /></strong>
        </p>

        <p style="margin-top: 0">
          Estes Termos serão regidos e interpretados de acordo com as leis da
          República Federativa do Brasil, sem levar em conta suas disposições
          sobre conflitos de leis.
        </p>
      </li>

      <li>
        Alterações
        <p>
          Reservamo-nos o direito, a nosso exclusivo critério, de modificar ou
          substituir estes Termos a qualquer momento. A menos que seja exigido
          por lei, notificaremos as alterações por meio de nosso Serviço e/ou
          qualquer canal de comunicação apropriado utilizado pela Otto Finanças
          (como, por exemplo, email) com pelo menos 30 dias de antecedência
          sobre os novos termos que entrarão em vigor, para que você possa
          revisá-los. Se você se opuser a alguma das alterações, poderá encerrar
          sua conta enviando um aviso por escrito dentro desse período de 30
          dias após o anúncio da atualização dos Termos &amp; Condições de Uso
          pela Otto Finanças.
        </p>

        <p>
          Continuar a acessar ou usar nosso site e app depois de qualquer
          revisão destes Termos implicará na concordância integral dos novos
          termos revisados.
        </p>
      </li>

      <li>
        Contato
        <p>
          Você pode entrar em contato conosco (com perguntas ou para fazer uma
          reclamação relacionada a qualquer elemento na prestação dos nossos
          Serviços) enviando-nos um e-mail para
          <a href="mailto:contato@ottofinancas.com.br" target="_blank"
            >contato@ottofinancas.com.br</a
          >
        </p>
      </li>

      <p style="margin-top: 50px">Última revisão: Setembro de 2021</p>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  color: #999898;
  font-weight: 500;
  font-size: 14px;
  margin: 14px 0;
}

a {
  font-size: 14px !important;
}

ol {
  list-style: decimal;
  list-style-position: inside;
  color: #737373;
}

@media (min-width: 720px) {
  ol {
    padding-left: 40px;
  }
}

ol > li {
  font-weight: bold;
  font-size: 19px;
  margin-top: 40px;
}

ul {
  padding-left: 40px;
}

ul > li {
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  color: #999898;
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
  color: #737373;
}

.inner-list {
  list-style: inside;
  font-size: 16px !important;
}
</style>
